li:not(:last-child) {
    margin-bottom: 5px;
}

.project {
    background-image: url('../assets/webScrap.png');
    background-repeat: no-repeat, no-repeat;
    background-position: center;
    background-size: contain;

}

.projectImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 6%;
}